



































import Site from "@/model/Site";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Changelog extends Vue {
  get site(): Site | null {
    return this.$store.state.site;
  }

  accessImportExport(customerId: string): boolean {
    return this.accessToImportExportCSV(customerId);
  }

  private accessToImportExportCSV(customerId?: string) {
    const customerIds = ["demo", "Y92", "Y57", "Z20", "A39", "A95", "A46", "A44", "A48", "B48", "Y55", "Z07", "Z25"];
    return customerIds.some(id => id === customerId);
  }
}
